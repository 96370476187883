
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/*#region General*/
* {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

html {
  font-size: 16px;
}

:root {
  --primary-color: #404040;
  --secondary-color: #1D3052;
  --white: whitesmoke;
  --gray: #D3D0CB;
}

body {
  background: var(--white);
  color: var(--primary-color);
  margin: 0;
}

a {
  color: var(--primary-color) !important;
}

@media (min-width: 1400px){
  .container {
    max-width: 1380px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1480px;
  }
}

@media (min-width: 1800px) {
  .container {
    max-width: 1700px;
  }
}

/*#endregion*/

/*#region Button*/
.btn-main {
  background: var(--secondary-color);
  border-radius: 6px;
  border: 0;
  color: var(--white);
  padding: 0.5rem 2rem;
  transition: .5s;
  font-weight: bold;
}
  .btn-main i {
    color: var(--white);
  }

  .btn-main:hover {
    background: rgba(29, 48, 82, 0.7);
    border: 0;
    color: var(--white);
  }

.btn-save {
  height: 56px;
  background: #47669D;
  border-radius: 6px;
  border: 0;
  color: var(--white);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
/*#endregion */

/*#region Utils*/
.color-blue {
  color: var(--secondary-color) !important;
}

.bg-light {
  background: #47669D !important;
}

.bg-blue {
  background: var(--secondary-color) !important;
}

.bg-alter {
  background: #ec008c;
}

.bg-alter-alpha {
  background: #F466BA;
}

.min-w {
  min-width: 950px;
  overflow-x: scroll;
}

.error {
  font-size: 0.9rem;
  color: darkred;
}

.pointer {
  cursor: pointer;
}
/*#endregion*/

/*#region Loader*/
.loader-container {
  height: 50px;
}

.loader {
  width: 8px;
  height: 40px;
  border-radius: 4px;
  display: block;
  margin: 20px auto;
  position: relative;
  background: var(--primary-color);
  color: #FFF;
  box-sizing: border-box;
  animation: animloader 0.5s 0.5s linear infinite alternate;
}

  .loader::after, .loader::before {
    content: '';
    width: 8px;
    height: 40px;
    border-radius: 4px;
    background: var(--primary-color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    box-sizing: border-box;
    animation: animloader 0.5s 0.65s linear infinite alternate;
  }

  .loader::before {
    left: -20px;
    animation-delay: 0s;
  }

@keyframes animloader {
  0% {
    height: 48px
  }

  100% {
    height: 4px
  }
}
/*#endregion*/

/*#region Option Menu*/
.options {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  position: fixed;
  right: 52px;
  bottom: 25px;
  background: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  transition: 2s;
  z-index: 10;
}

.options-menu {
  position: fixed;
  right: 20px;
  bottom: 80px;
  background: white;
  padding: 2rem;
  border-radius: 6px;
  transition: 4s;
  z-index: 10;
}
/*#endregion*/

/* #region ScrollBar */
* {
  scrollbar-width: auto;
  scrollbar-color: #47669D whitesmoke;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: whitesmoke;
}

*::-webkit-scrollbar-thumb {
  background-color: #47669D;
  border-radius: 6px;
  border: 2px solid whitesmoke;
}
/*#endregion*/

/*#region Angular*/
.mat-mdc-table .mdc-data-table__row {
  height: 70px;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: transparent;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: transparent;
}

.mat-mdc-form-field-focus-overlay {
  background: transparent;
}

.mat-mdc-table {
  border-radius: 15px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: white
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0
}

.hours_input .mat-mdc-form-field-infix {
  width: 40px !important;
}

/*#endregion*/

/*#region Table*/
.timerep_table thead {
  background: #47669D !important;
}

.timerep_table th {
  color: white !important;
  padding: 1rem 2rem !important;
  font-weight: bold !important;
}

.timerep_table td {
  padding: 1rem 2rem;
}

.timerep_table th:first-child {
  border-top-left-radius: 6px;
}

.timerep_table th:last-child {
  border-top-right-radius: 6px;
}

.timerep_table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

.timerep_table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.timerep_table tr:last-child .inner-table:last-child td:first-child{
  border-bottom-left-radius: 6px;
}

.inner-table tr:last-child td:first-child,
.inner-table tr:last-child td:last-child {
  border-radius: 0;
}

input {
  background: transparent !important;
  border-radius: 6px;
  border: 1px solid var(--gray);
  border-color: var(--gray) !important;
  box-shadow: none !important;
  font-size: 14px;
  padding: 0.5rem 2rem 0.5rem 1rem;
}

  input:focus {
    border: 1px solid var(--secondary-color) !important;
  }

label {
  text-align: left;
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}
/*#endregion*/

/*#region chart*/
.nav-tabs{
  border-bottom: none;
}
.nav-link{
  color: black !important;
}
.tab-report-chart{
  width: 200px;
}
#myTabContent{
  margin-top: 2px;
}
.noActivity{
  padding-top: 200px;
}
/*#endregion*/
